import React from 'react';
import { Link } from 'gatsby';
import '../css/tags.css';


const SingleTagTemplate =({data,pageContext}) =>{
    const {posts, tagName} = pageContext;
    return (
        <div>
            <div>
                 post about <p className="tag">{tagName}</p>
                 <div>
                     <ul>
                         {posts.map((post, index)=>{
                             return (
                                 <li key={index}>
                                    <Link to={post.frontmatter.path}>
                                       {post.frontmatter.title}
                                    </Link>
                                 </li>
                             )
                         })}
                     </ul>
                 </div>
            </div>
        </div>
    )
}

export default SingleTagTemplate;

